<template>
  <div>
    <div class="error">
      <h1>404</h1>

      <div class="error-message">
        Извините! Страница, которую Вы ищете, не найдена.
      </div>

      <div class="error-link" @click="goBack">
        На главную
      </div>
    </div>
  </div>
</template>

<script>
import { isTokenValid } from "@/function/checkExpiredToken";

export default {
  computed: {
    validTkn() {
      return isTokenValid();
    }
  },
  methods: {
    goBack() {
      this.validTkn
        ? this.$router.push({ name: "Main" })
        : this.$router.push({ name: "Auth" });
    }
  }
};
</script>

<style scoped lang="scss">
.error {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: center;
  padding: 0 15px;

  h1 {
    color: #51a2be7d;
    font-size: 10rem;
  }

  &-message {
    font-size: 26px;
    padding: 5px 10px;
    z-index: 1;
  }

  &-link {
    font-size: 19px;
    margin-top: 20px;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    background-color: #51a2be;
    color: white;
    transition: all 0.2s ease;
    border: 1px white solid;
    text-decoration: underline;

    &:hover {
      background-color: white;
      color: #51a2be;
      border: 1px #51a2be solid;
    }
  }
}
</style>
